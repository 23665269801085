import "../../App.css";
import HeroSection from "../HeroSection";
import React from "react";

function Home() {
  return (
    <>
      <HeroSection />
    </>
  );
}

export default Home;
